import { MsalAuthProvider, LoginType } from "react-aad-msal";

const {
  REACT_APP_SSO_AUTHORITY,
  REACT_APP_SSO_REDIRECT_URL,
  REACT_APP_SSO_CLIENT_ID,
} = process.env;

// Msal Configurations
const config = {
  auth: {
    authority: REACT_APP_SSO_AUTHORITY,
    clientId: REACT_APP_SSO_CLIENT_ID,
    redirectUri: REACT_APP_SSO_REDIRECT_URL,
  },
};

// Authentication Parameters
const authenticationParameters = {
  scopes: ["openid", "profile", "email", "user.read"],
};

// Options
const options = {
  loginType: LoginType.Popup,
  tokenRefreshUri: window.location.origin + "/auth.html",
};

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);
