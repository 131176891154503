import React from "react";
import { authProvider } from "../Utils/authProvider";
import { AzureAD, AuthenticationState } from "react-aad-msal";

import Header from "../components/Header";
import YouTubeTerms from "../components/YouTubeTerms";
import AddToYouTube from "../components/AddToYouTube";
import YouTubePublishData from "../components/YouTubePublishData";

export default function HomePage() {
  const [showData, setShowData] = React.useState(false);
  const [seed, setSeed] = React.useState(1);
  const refreshHandler = () => {
    setSeed(Math.random());
  };
  return (
    <div className="container">
      <AzureAD provider={authProvider} forceLogin={true}>
        {({ login, logout, authenticationState, error, accountInfo }) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              setShowData(true);
              return (
                showData && (
                  <>
                    <Header
                      userName={accountInfo.account.name}
                      logoutUrl={logout}
                    ></Header>
                    <AddToYouTube
                      email={accountInfo.account.idToken.mail}
                      onRefresh={() => refreshHandler()}
                    ></AddToYouTube>
                    <YouTubeTerms></YouTubeTerms>
                    <YouTubePublishData key={seed}></YouTubePublishData>
                  </>
                )
              );
            case AuthenticationState.Unauthenticated:
              console.log("not authenticated");
              return (
                <div>
                  {error && (
                    <p>
                      <span>
                        An error occured during authentication, please try
                        again!
                      </span>
                    </p>
                  )}
                  <p>
                    <span>Hey stranger, you look new!</span>
                    <button onClick={login}>Login</button>
                  </p>
                </div>
              );
            case AuthenticationState.InProgress:
              console.log("In Progress");
              return <p>Authenticating...</p>;
            default:
              return <p></p>;
          }
        }}
      </AzureAD>
    </div>
  );
}
