import axios from "axios";

const {
  REACT_APP_YOUTUBEDATA_API_URL,
  REACT_APP_APPDATA_API_URL,
  REACT_APP_YOUTUBEDATA_BYVIMEOID_API_URL,
  REACT_APP_SAVE_YOUTUBE_DATA_API_URL,
  REACT_APP_UPDATE_YOUTUBE_VISIBILITY_API_URL,
  REACT_APP_X_API_KEY,
} = process.env;

const headers = {
  "X-API-Key": REACT_APP_X_API_KEY,
};

export const fetchYouTubeData = async () => {
  return axios
    .get(REACT_APP_YOUTUBEDATA_API_URL, {
      headers,
    })
    .then((res) => {
      return res.data;
    });
};

export const fetchAppData = async (VimeoId) => {
  return axios
    .get(REACT_APP_APPDATA_API_URL + VimeoId, {
      headers,
    })
    .then((res) => {
      return res.data;
    });
};

export const fetchYouTubeDataByVimeoId = async (VimeoId) => {
  return axios
    .get(REACT_APP_YOUTUBEDATA_BYVIMEOID_API_URL + VimeoId, {
      headers,
    })
    .then((res) => {
      return res.data;
    });
};

export const saveYouTubeData = async (
  appName,
  vimeoId,
  title,
  createdBy,
  createdOn
) => {
  var data = {
    AppName: appName,
    VimeoId: vimeoId,
    Title: title,
    CreatedBy: createdBy,
    CreatedOn: createdOn,
  };
  console.log(headers);
  return axios
    .post(REACT_APP_SAVE_YOUTUBE_DATA_API_URL, data, {
      headers,
    })
    .then((res) => {
      console.log(res.data);
      return res.data;
    });
};

export const updateYouTubeVisibility = async (
  appName,
  vimeoId,
  youTubeVisibility,
  youTubeCode
) => {
  var data = {
    AppName: appName,
    VimeoId: vimeoId,
    Visibility: youTubeVisibility,
    YouTubeCode: youTubeCode,
  };

  return axios
    .post(REACT_APP_UPDATE_YOUTUBE_VISIBILITY_API_URL, data, {
      headers,
    })
    .then((res) => {
      return res.data;
    });
};
