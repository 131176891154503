import React from "react";

// import { Routes, Route } from "react-router-dom";

// We will create these two pages in a moment

import HomePage from "./pages/HomePage";

import "./App.css";

import "./fonts/lineto-brown-regular.woff2";
import "./fonts/lineto-brown-regular.woff";
import "./fonts/lineto-brown-regular.eot";

import "./fonts/lineto-brown-light.woff2";
import "./fonts/lineto-brown-light.woff";
import "./fonts/lineto-brown-light.eot";

import "./fonts/lineto-brown-bold.woff2";
import "./fonts/lineto-brown-bold.woff";
import "./fonts/lineto-brown-bold.eot";

function App() {
  return (
    // <Routes>
    //   <Route exact path="/" element={<HomePage />} />

    //   <Route path="/up" element={<UserPage />} />
    // </Routes>
    <HomePage></HomePage>
  );
}

export default App;
