import React from "react";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import ToggleSwitch from "./ToggleSwitch";
import Popup from "reactjs-popup";
import { updateYouTubeVisibility } from "../Utils/awsFunctions";
import { ReactComponent as ProcessingIcon } from "../images/processing.svg";

const tableHead = {
  Title: "Segment Title",
  VimeoId: "Vimeo ID",
  CreatedOn: "Uploaded On",
  CreatedBy: "Added By",
  YouTubeStatus: "YouTube Status",
  YouTubeVisibility: "YouTube Visibility",
};

export default function TablePagination({ youtubeData }) {
  const countPerPage = 10;
  const [value, setValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isOpen, setIsOpen] = React.useState(false);

  const [collection, setCollection] = React.useState(
    cloneDeep(youtubeData.slice(0, countPerPage))
  );
  const searchData = React.useRef(
    throttle((val) => {
      const query = val.toLowerCase();
      setCurrentPage(1);
      const data = cloneDeep(
        youtubeData
          .filter(
            (item) =>
              item.Title.toLowerCase().indexOf(query) > -1 ||
              item.CreatedBy.toLowerCase().indexOf(query) > -1 ||
              ("" + item.VimeoId).indexOf(query) > -1
          )
          .slice(0, countPerPage)
      );
      setCollection(data);
    }, 400)
  );

  React.useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(youtubeData.slice(from, to)));
  };

  const tableRows = (rowData) => {
    const { key, index } = rowData;

    const isVideoPublic =
      key["YouTubeVisibility"] === "null" ||
      key["YouTubeVisibility"] === "" ||
      key["YouTubeVisibility"].toUpperCase() === "UNLISTED" ||
      key["YouTubeVisibility"].toUpperCase() === "PRIVATE"
        ? false
        : true;

    return (
      <tr
        key={index}
        className={` ${index % 2 === 0 ? "TableRow" : "TableRowAlt"}`}
      >
        <td key={0} className="TableCell">
          <span className="TableHyperlink">{key["Title"]}</span>
        </td>
        <td key={1} className="TableExceptTitleCol">
          {key["VimeoId"]}
        </td>
        <td key={2} className="TableCell TableExceptTitleCol">
          {new Date(key["CreatedOn"]).toLocaleString()}
        </td>
        <td key={3} className="TableExceptTitleCol">
          {key["CreatedBy"]}
        </td>
        <td key={4} className="TableExceptTitleCol">
          {key["YouTubeCode"] === "null" || key["YouTubeCode"] === "" ? (
            <ProcessingIcon></ProcessingIcon>
          ) : (
            <a
              href={"https://www.youtube.com/watch?v=" + key["YouTubeCode"]}
              className="TableHyperlink"
              target={"_blank"}
              rel={"noreferrer"}
            >
              {capitalizeFirstLowercaseRest(key["YouTubeStatus"])}
            </a>
          )}
        </td>
        {key["YouTubeCode"] === "null" || key["YouTubeCode"] === "" ? (
          <td key={5} className="TableExceptTitleCol">
            ---
          </td>
        ) : (
          <td key={5} className="TableExceptTitleCol">
            <span
              className="VisibilityStatus"
              style={
                isVideoPublic
                  ? { fontFamily: "BrownStd-Light" }
                  : { fontFamily: "BrownStd-Bold" }
              }
            >
              Private
            </span>
            {isVideoPublic && (
              <ToggleSwitch
                Name={"visibility" + key["YouTubeCode"]}
                Checked={"true"}
                onChange={(e) =>
                  onVisibilityChange(
                    e,
                    key["AppName"],
                    key["VimeoId"],
                    key["YouTubeCode"]
                  )
                }
              ></ToggleSwitch>
            )}
            {!isVideoPublic && (
              <ToggleSwitch
                Name={"visibility" + key["YouTubeCode"]}
                onChange={(e) =>
                  onVisibilityChange(
                    e,
                    key["AppName"],
                    key["VimeoId"],
                    key["YouTubeCode"]
                  )
                }
              ></ToggleSwitch>
            )}
            <span
              className="VisibilityStatus"
              style={
                isVideoPublic
                  ? { fontFamily: "BrownStd-Bold" }
                  : { fontFamily: "BrownStd-Light" }
              }
            >
              Public
            </span>
          </td>
        )}
      </tr>
    );
  };

  const capitalizeFirstLowercaseRest = (str) => {
    return str == null || str === ""
      ? str
      : str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const tableData = () => {
    return collection.map((key, index) => tableRows({ key, index }));
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <td key={index} className={` ${index === 0 ? "TableCell" : ""}`}>
        {title}
      </td>
    ));
  };

  const onVisibilityChange = (e, AppName, VimeoId, YouTubeCode) => {
    const newValue = e.target.checked;

    if (e.target.checked) {
      if (window.confirm(" Are you sure you want to make this public? ")) {
        console.log("Value changed to Public");
        updateYouTubeVisibility(AppName, VimeoId, "Public", YouTubeCode);
      } else {
        e.target.checked = !newValue;
      }
    } else {
      console.log("Value changed to Private");
      updateYouTubeVisibility(AppName, VimeoId, "Private", YouTubeCode);
    }

    console.log("Visibility changed from " + newValue);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const changeVisibility = () => {};

  return (
    <>
      <div className="Search">
        <table>
          <thead>
            <tr>
              <td style={{ width: "1300px" }}>
                <span className="TableTitle">Segment</span>
              </td>
              <td>
                <input
                  className="SearchText"
                  placeholder="Search"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </td>
            </tr>
          </thead>
        </table>
      </div>

      <table className="Table">
        <thead className="TableHeader">
          <tr>{headRow()}</tr>
        </thead>
        <tbody className="trhover">{tableData()}</tbody>
      </table>
      {!value && (
        <Pagination
          pageSize={countPerPage}
          onChange={updatePage}
          current={currentPage}
          total={youtubeData.length}
          className="TableFooter"
        />
      )}

      <Popup open={isOpen} modal nested style={{ width: "50%" }}>
        <div
          style={{
            width: "100%",
            height: "42px",
            backgroundColor: "#0089CF",
          }}
        >
          <span
            style={{
              fontFamily: "BrownStd-Bold",
              fontSize: "24px",
              color: "white",
              marginLeft: "10px",
              letterSpacing: "0.48px",
              position: "relative",
              top: "10%",
            }}
          >
            YouTube Visibility
          </span>
        </div>
        <div style={{ margin: "30px" }}>
          <div>
            <span style={{ fontFamily: "BrownStd-Regular", fontSize: "24px" }}>
              {"Are you sure you want to make this public?"}
            </span>
          </div>
        </div>
        <hr style={{ lineHeight: "0.1px", margin: "30px" }} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <button className="PopupButton" onClick={(e) => changeVisibility(e)}>
            Yes
          </button>
          <span style={{ width: "20px" }} />
          <button className="PopupButtonGray" onClick={closePopup}>
            No
          </button>
        </div>
      </Popup>
    </>
  );
}
