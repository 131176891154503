import { fetchYouTubeData } from "../Utils/awsFunctions";
import TablePagination from "./TablePagination";
import React, { useState, useEffect } from "react";

export default function YouTubePublishData() {
  const [youtubeData, setYouTubeData] = useState([]);

  useEffect(() => {
    (async () => {
      const returnData = await fetchYouTubeData();
      const descendingData = [...returnData].sort((a, b) =>
        a.CreatedOn > b.CreatedOn ? -1 : 1
      );
      setYouTubeData(descendingData);
    })();
  }, []);

  return (
    <div>
      <br />
      {youtubeData.length && (
        <TablePagination youtubeData={youtubeData}></TablePagination>
      )}
    </div>
  );
}
