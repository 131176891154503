import React from "react";
export default function YouTubeTerms() {
  return (
    <div style={{ width: "1753px" }}>
      <table style={{ width: "100%" }}>
        <tbody style={{ width: "100%" }}>
          <tr style={{ width: "100%" }}>
            <td style={{ width: "100%", textAlign: "right" }}>
              <a
                className="TermsAndPrivacy"
                target={"_blank"}
                rel="noreferrer"
                href="https://www.youtube.com/t/terms"
              >
                YouTube Terms of Service
              </a>
              &nbsp;|&nbsp;
              <a
                className="TermsAndPrivacy"
                target={"_blank"}
                rel="noreferrer"
                href="https://policies.google.com/privacy?hl=en"
              >
                Google Privacy Policy
              </a>
              &nbsp;
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
