import React, { useEffect } from "react";
import Popup from "reactjs-popup";
import {
  fetchAppData,
  fetchYouTubeDataByVimeoId,
  saveYouTubeData,
} from "../Utils/awsFunctions";
import "reactjs-popup/dist/index.css";

export default function AddToYouTube(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [textValue, setTextValue] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [appName, setAppName] = React.useState("");

  const VimeoIdChange = (e) => {
    setTextValue(e.target.value);
    if (e.target.value.length < 9 && e.target.value.length !== 0) {
      setShowError(true);
      setErrorMessage("Invalid Vimeo ID.");
    } else {
      setShowError(false);
    }
  };

  const AddClick = () => {
    fetchYouTubeDataByVimeoId(textValue).then((x) => {
      const existingItem = x.length > 0;

      setShowError(existingItem);

      if (!existingItem) {
        fetchAppData(textValue).then((appData) => {
          if (appData.length > 0) {
            appData.forEach((data) => {
              setTitle(data.Title);
              setDescription(data.Description);
              setAppName(data.AppName);
            });
            setIsOpen(!isOpen);
          } else {
            setShowError(true);
            setErrorMessage("Vimeo ID not found.");
          }
        });
      } else
        setErrorMessage(
          "A video with this Vimeo ID already exists on YouTube."
        );
    });
  };

  const saveYouTubeDataEvent = () => {
    (async () => {
      await saveYouTubeData(
        appName,
        textValue,
        title,
        props.email,
        new Date().toJSON()
      );
    })().then((res) => {
      setIsOpen(!isOpen);
      props.onRefresh();
      setTextValue("");
      const countdownInterval = setTimeout(() => {
        console.log("Refreshed table!");
        props.onRefresh();
        clearTimeout(countdownInterval);
      }, 150000);
    });
  };
  const closePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div>
        <h3 className="Title">Publish to YouTube</h3>
      </div>
      <div>
        <div>
          <span className="SubTitle">Vimeo ID</span>&nbsp;
          <input
            type="number"
            pattern="[0-9]*"
            inputMode="numeric"
            className="TextBox"
            onChange={(e) => VimeoIdChange(e)}
            onPaste={(e) => VimeoIdChange(e)}
            title="Vimeo Id"
            value={textValue}
          ></input>
          <button
            className="Button"
            value={"Add"}
            onClick={AddClick}
            type="button"
            disabled={showError}
          >
            Add
          </button>
          {showError && (
            <div className="ErrorDiv">
              <span id="spnError" style={{ padding: "10px 15px" }}>
                {errorMessage}
              </span>
            </div>
          )}
        </div>
        <Popup open={isOpen} modal nested>
          <div
            style={{
              width: "100%",
              height: "42px",
              backgroundColor: "#0089CF",
            }}
          >
            <span
              style={{
                fontFamily: "BrownStd-Bold",
                fontSize: "24px",
                color: "white",
                marginLeft: "10px",
                letterSpacing: "0.48px",
                position: "relative",
                top: "10%",
              }}
            >
              Confirm publish to YouTube
            </span>
          </div>
          <div style={{ margin: "30px" }}>
            <div style={{ fontFamily: "BrownStd-Regular", fontSize: "24px" }}>
              Vimeo Id:&nbsp;&nbsp;
              <span style={{ fontFamily: "BrownStd-Bold", fontSize: "24px" }}>
                {textValue}
              </span>
            </div>
            <div style={{ height: "20px" }}></div>
            <div style={{ fontFamily: "BrownStd-Regular", fontSize: "24px" }}>
              Title:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ fontFamily: "BrownStd-Bold", fontSize: "24px" }}>
                {title}
              </span>
            </div>
            <div style={{ height: "20px" }}></div>
            <div style={{ fontFamily: "BrownStd-Regular", fontSize: "24px" }}>
              Description:
            </div>
            <div style={{ height: "10px" }}></div>
            <div
              style={{
                fontFamily: "BrownStd-Regular",
                fontSize: "24px",
                paddingLeft: "10px",
                height: "200px",
                overflow: "auto",
              }}
            >
              {description}
            </div>
          </div>
          <hr style={{ lineHeight: "0.1px", margin: "30px" }} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginBottom: "20px",
            }}
          >
            <button className="PopupButton" onClick={saveYouTubeDataEvent}>
              Yes
            </button>
            <span style={{ width: "20px" }} />
            <button className="PopupButtonGray" onClick={closePopup}>
              No
            </button>
          </div>
        </Popup>
      </div>
    </>
  );
}
