import { ReactComponent as CnmLogo } from "../images/Logo - Large.svg";
import { ReactComponent as VocrmLogo } from "../images/Frame.svg";
import React from "react";
import { ReactComponent as ChevronDownIcon } from "../images/chevron.svg";

export default function Header(props) {
  const [showLogout, setShowLogout] = React.useState(false);

  const mountedStyle = {
    animation: "inAnimation 500ms ease-in",
  };
  const unmountedStyle = {
    animation: "outAnimation 500ms ease-out",
    animationFillMode: "forwards",
  };

  function toggle() {
    setShowLogout(!showLogout);
  }

  return (
    <div className="App-header">
      <div style={{ height: "100%" }}>
        <table style={{ width: "100%", height: "100%" }}>
          <tbody>
            <tr>
              <td className="CnmLogo">
                <CnmLogo />
              </td>
              <td className="VocrmLogo">
                <VocrmLogo />
              </td>
              <td
                style={{
                  textAlign: "right",
                  color: "white",
                  width: "58%",
                  fontFamily: "BrownStd-Regular",
                }}
              >
                <table style={{ width: "100%", height: "100%" }}>
                  <tbody>
                    <tr style={{ width: "100%", height: "59%" }}>
                      <td style={{ verticalAlign: "bottom" }}>
                        <span className="User" onMouseOver={toggle}>
                          {props.userName}
                        </span>
                        <ChevronDownIcon
                          fill="white"
                          className="UserChevron"
                          height={"20px"}
                          onMouseOver={toggle}
                        ></ChevronDownIcon>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ height: "40%" }}>
                        {showLogout && (
                          <span
                            className="Logout"
                            onClick={props.logoutUrl}
                            // style={{ border: "solid", padding: "7px" }}
                            style={showLogout ? mountedStyle : unmountedStyle}
                          >
                            Logout
                          </span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
